import './App.css';
import { useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';

function App() {
  const form = useRef()
  const submitForm = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_ubu48tr', 'template_r7brxh6', form.current, 'vXlqXnmluRTml600V')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }
  useEffect(() => {
    // console.log(fs.readdir())
  }, []);
  return (
    <>

      {/* <!-- ================================================   Donate Now Btn   ================================================ --> */}
      <a href="#donate" className="btn_Donate">Donate Now</a>

      {/* <!-- ================================================   Header   ================================================ --> */}
      <header id="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light py-3">
            <div className="container-fluid">
              <a href="/" className="fw-bolder logo_txt text-start">
                {/* <!-- Sadbhavna Charitable <br>
                            And Education Trust --> */}
                <img src="./image/Logo_Name.png" alt="Logo Name" style={{ height: "60px" }} />
              </a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="navIcon"><i className="fas fa-bars"></i></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav cus_navbar mb-2 mb-lg-0 ms-auto">
                  <li className="nav-item ms-md-3">
                    <a className="nav-link active fw-bold" aria-current="page" href="#about_us"><i
                      className="fas fa-angle-right me-2"></i>About Us</a>
                  </li>
                  <li className="nav-item ms-md-3">
                    <a className="nav-link active fw-bold" aria-current="page" href="#mission_vision"><i
                      className="fas fa-angle-right me-2"></i>Mission &
                      Vision</a>
                  </li>
                  <li className="nav-item ms-md-3">
                    <a className="nav-link active fw-bold" aria-current="page" href="#upcoming_projects"><i
                      className="fas fa-angle-right me-2"></i>Upcoming
                      Projects</a>
                  </li>
                  <li className="nav-item ms-md-3">
                    <a className="nav-link active fw-bold" aria-current="page" href="#goal_motive"><i
                      className="fas fa-angle-right me-2"></i>Goal & Motive</a>
                  </li>
                  <li className="nav-item ms-md-3">
                    <a className="nav-link active fw-bold" aria-current="page" href="#contact_us"><i
                      className="fas fa-angle-right me-2"></i>Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {/* <!-- ================================================   Banner   ================================================ --> */}

      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active"
            aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
            aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
            aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="./image/banner-1.png" className="img-fluid mediaBanner" alt="banner-1" draggable="false" />
            {/* <!-- <div className="carousel-caption d-flex justify-content-center">
                    <p className="bannerTxt">Someone Had Said That If You Want To Destroy A Nation Without War, Just Spoil
                        Their
                        Education System LET’S BUILT OUR NATION BY EDUCATION</p>
                </div> --> */}
          </div>
          <div className="carousel-item">
            <img src="./image/banner-2.png" className="mediaBanner img-fluid" alt="banner-2" draggable="false" />
            {/* <!-- <div className="carousel-caption d-flex justify-content-center">
                    <p className="bannerTxt">Corporate, government , and social organizations are the mentor to work in the
                        direction
                        of students bright future.
                    </p>
                </div> --> */}
          </div>
          <div className="carousel-item">
            <img src="./image/banner-3.png" className="mediaBanner img-fluid" alt="banner-3" draggable="false" />
            {/* <!-- <div className="carousel-caption d-flex justify-content-center">
                    <p className="bannerTxt">Some representative placeholder content for the third slide.</p>
                </div> --> */}
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* <!-- ================================================   About Us   ================================================ --> */}

      <section id="about_us">
        <div className="container">

          <div className="d-flex my-4 my-lg-5">
            <div className="mx-auto">
              <h3 className="headding m-0">About Us</h3>
              <div className="underline"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img src="./image/Logo1.jpg" alt="Gujrat" className="img-fluid" style={{ width: "500px" }} draggable="false" />
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 my-2 my-lg-0">
              <div className="card cardBorder p-2 p-lg-4">
                <div className="card-body">
                  <div>
                    <i
                      className="fas fa-building card_icon cw d-flex justify-content-center align-items-center mx-auto"></i>
                  </div>
                  <h3 className="card-title text-center headFont headTxt mt-2 mt-lg-4">Objective</h3>
                  <p className="card-text text-center petaFontColor lh-base petaTxt my-2 my-lg-3">Our objective is
                    to work for the
                    betterment of SOCIETY AND WORLD WITH THE HELP OF NEW GENERATION. We want to make a good
                    SOCIETY AND WORLD for ALL.</p>
                  <div className="d-flex">
                    <a href="#contact_us" className="btn rounded-pill btn-primary mx-auto mt-2">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-2 my-lg-0">
              <div className="card cardBorder p-2 p-lg-4">
                <div className="card-body">
                  <div>
                    <i
                      className="far fa-bullseye-arrow card_icon cw d-flex justify-content-center align-items-center mx-auto"></i>
                  </div>
                  <h3 className="card-title text-center headFont headTxt mt-2 mt-lg-4">Thought</h3>
                  <p className="card-text text-center petaFontColor lh-base petaTxt my-2 my-lg-3">When we think of
                    what we will
                    leave for our kids, the only thing that comes in our mind is a GOOD SOCIETY AND WORLD.
                    What beter thing than a good SOCIETY AND WORLD to live in. And in order to do that, WE
                    BELIEVE THAT THE students
                    from a young age CAN DO THIS SO WE FOCUSED ON THEM.</p>
                  <div className="d-flex">
                    <a href="#contact_us" className="btn rounded-pill btn-primary mx-auto mt-2">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-2 my-lg-0">
              <div className="card cardBorder p-2 p-lg-4">
                <div className="card-body">
                  <div>
                    <i
                      className="fad fa-head-side-brain objIcn card_icon cw d-flex justify-content-center align-items-center mx-auto"></i>
                  </div>
                  <h3 className="card-title text-center headFont headTxt mt-2 mt-lg-4">Established</h3>
                  <p className="card-text text-center petaFontColor lh-base petaTxt my-2 my-lg-3">We established
                    this trust in
                    2012. We want to take this trust to a new height and work in order to build a new &
                    better society.</p>
                  <div className="d-flex">
                    <a href="#contact_us" className="btn rounded-pill btn-primary mx-auto mt-2">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      {/* <!-- ================================================   Mission & Vision   ================================================ --> */}

      <section id="mission_vision" className="missSection">
        <div>
          <img src="./image/gujrat_1.png" alt="Gujrat" className="missGuj" draggable="false" />
        </div>
        <div className="container">
          <div className="d-flex my-4 my-lg-5">
            <div className="mx-auto">
              <h3 className="headding m-0">Mission & Vision</h3>
              <div className="underline"></div>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-6">
              <img src="./image/mission_img.png" alt="Mission image" draggable="false" className="img-fluid img_ani" />
            </div>
            <div className="col-lg-6 d-flex flex-column align-items-center justify-content-evenly">
              <div className="text-center d-inline-block mx-auto">
                <div className="d-flex justify-content-center mt-2 mt-lg-4">
                  {/* <!-- <h3 className="headFont ms-2"><i className="far fa-bullseye-arrow missionIcn me-3"></i>Our Mission</h3> --> */}
                  <i className="far fa-bullseye-arrow missionIcn"></i>
                  <h3 className="headFont headtxt ms-2">Our Mission</h3>
                </div>
                <p className="petaFont petaFontColor petaTxt mt-2 missTxt mx-auto">
                  Our mission is to visit each and every high schools of Gujarat and spread awareness
                  regarding our cause.
                </p>
              </div>

              <div className="text-center d-inline-block mx-auto">
                <div className="d-flex justify-content-center mt-2 mt-lg-4">
                  {/* <!-- <h3 className="headFont ms-2"><i className="far fa-bullseye-arrow missionIcn me-3"></i>Our Mission</h3> --> */}
                  <i className="fas fa-eye missionIcn"></i>
                  <h3 className="headFont headtxt ms-2">Our Vision</h3>
                </div>
                <p className="petaFont petaFontColor petaTxt mt-2 missTxt mx-auto">
                  Complete development of the students in terms of education, career, heath and social cause
                  like disaster management, water harvesting, tree plantation and pollution /garbage control
                  management.
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* <!-- ================================================   Upcoming Projects   ================================================ --> */}

      <section id="upcoming_projects" className="upcomSection">
        <img src="./image/gujrat_2.png" alt="Gujrat" className="upcomGuj" draggable="false" />
        <div className="container">

          <div className="d-flex my-4 my-lg-5">
            <div className="mx-auto">
              <h3 className="headding m-0">Upcoming Projects</h3>
              <div className="underline"></div>
            </div>
          </div>

          {/* <!-- ======================= Umang : The Steps Of Life ====================== --> */}
          <div className="row">
            <div className="col-lg-12 d-flex">
              <img src="./image/saksham.png" className="mx-auto mb-4 mb-lg-5 img-fluid"
                alt="Umang : The Steps Of Life" draggable="false" />
            </div>
          </div>
          {/* <!-- ======================= Umang  Details ====================== --> */}
          <div className="row align-items-center">
            <div className="col-lg-6 p-2">
              <img src="./image/OPPORTUNITY.png" alt="OPPORTUNITY" draggable="false"
                className="img-fluid umangDetailsImg p-2 img_ani_2" />
            </div>
            <div className="col-lg-6 p-lg-4 ps-5">
              <h3 className="headFont missHead p-0">EDUCATION</h3>
              <ul className="missUl m-0">
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Courses
                </li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Self Estimate</li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Techniques
                </li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Responsibility of Study</li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Goal Setting</li>
              </ul>
            </div>
          </div>

          <div className="row flex-column-reverse flex-lg-row align-items-center">
            <div className="col-lg-6 p-lg-4 ps-5">
              <h3 className="headFont missHead p-0">SCOPE</h3>
              <ul className="missUl m-0">
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Job (Govt. & Private)</li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Freelance
                </li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Business (Manufacturing, Trading, Service Etc.)</li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Startup
                </li>
              </ul>
            </div>
            <div className="col-lg-6 p-2">
              <img src="./image/SCOP.png" alt="SCOP" draggable="false"
                className="img-fluid umangDetailsImg p-2 img_ani_2" />
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6 p-2">
              <img src="./image/SPORTS.png" alt="SPORTS" draggable="false"
                className="img-fluid umangDetailsImg p-2 img_ani_2" />
            </div>
            <div className="col-lg-6 p-lg-4 ps-5">
              <h3 className="headFont missHead p-0">SPORTS & HEALTH</h3>
              <ul className="missUl m-0">
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Arrangement Of Tournaments.</li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Help to Khelo India & Fit India Projects. </li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  We Will Identify The Skill And Will Promote Them.</li>
                <li className="d-flex align-items-center"><i
                  className="fad fa-angle-double-right missionIcn me-2"></i>Basic Health Tips.</li>
              </ul>
            </div>
          </div>

          <div className="row flex-column-reverse flex-lg-row align-items-center">
            <div className="col-lg-6 p-lg-4 ps-5">
              <h3 className="headFont missHead p-0">SOCIAL</h3>
              <ul className="missUl m-0">
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Disaster Management</li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Water Saving</li>
                <li className="d-flex align-items-center"><i className="fad fa-angle-double-right missionIcn me-2"></i>
                  Tree Plantation</li>
                <li className="d-flex align-items-center"><i
                  className="fad fa-angle-double-right missionIcn me-2"></i>Garbage / pollution control</li>
              </ul>
            </div>
            <div className="col-lg-6 p-2">
              <img src="./image/SOCIAL.png" alt="SOCIAL" draggable="false"
                className="img-fluid umangDetailsImg p-2 img_ani_2" />
            </div>
          </div>

        </div>
      </section>

      {/* <!-- ================================================   WORKING PLAN   ================================================ --> */}
      {/* <!-- <section className="WorkingBG mt-4 mt-lg-5 py-4 py-lg-5">
        <div className="container">
            <div className="row align-items-center flex-column-reverse flex-lg-row">
                <div className="col-lg-6">
                    <div className="my-2 my-lg-4">
                        <h3 className="workingHead m-0">WORKING PLAN</h3>
                        <div className="underline"></div>
                    </div>
                    <ul className="arrowTxt">
                        <li className="d-flex align-items-start f-20 pt-3 pt-lg-4"><i
                                className="fad fa-angle-double-right missionIcn me-2"></i>Time Duration:- 01/12/2020 To
                            28/02/2021</li>
                        <li className="d-flex align-items-start f-20 pt-3 pt-lg-4"><i
                                className="fad fa-angle-double-right missionIcn me-2"></i>Available Days :- 52 Days
                            (Approx.)</li>
                        <li className="d-flex align-items-start  f-20 pt-3 pt-lg-4"><i
                                className="fad fa-angle-double-right missionIcn me-2"></i>On Day One Our Executive Will
                            Visit The School And Will Brief The Principal About The Concept. Next Day Executive Will
                            Guide The Students About Education, Career, Sports And Disaster Management.</li>
                        <li className="d-flex align-items-start f-20 pt-3 pt-lg-4"><i
                                className="fad fa-angle-double-right missionIcn me-2"></i>One Executive Will Attend 25
                            Schools During This Period.</li>
                    </ul>
                </div>

                <div className="col-lg-6">
                    <img src="./image/Working_Plan.png" alt="Working Plan" draggable="false" className="img-fluid img_ani" />
                </div>
            </div>
        </div>
    </section> --> */}

      {/* <!-- ================================================   Approaching Schools   ================================================ --> */}
      <section>
        <div className="container">

          <div className="d-flex my-4 my-lg-5">
            <div className="mx-auto">
              <h3 className="headding m-0">Approaching Schools</h3>
              <div className="underline"></div>
            </div>
          </div>

          <div className="col-lg-12">
            <img src="./image/11998+_schools.png" alt="200+ Schools" draggable="false"
              className="img-fluid d-flex mx-auto" />
          </div>

        </div>
      </section>

      {/* <!-- ================================================   Our works   ================================================ --> */}
      <section className="galleryBG">

        <div className="container">
          <div className="row">
            <div className="d-flex my-4 my-lg-5">
              <div className="mx-auto">
                <h3 className="headding tc-white m-0">Our Works</h3>
                <div className="underline"></div>
              </div>
            </div>
          </div>
        </div>


        <div className="owl-carousel gallery owl-theme">
                        <img src="/images/1.jpeg"  alt="Gallery" draggable="false" className="galleryImg" />
                        <img src="/images/2.jpeg"  alt="Gallery" draggable="false" className="galleryImg" />
                        <img src="/images/3.jpeg"  alt="Gallery" draggable="false" className="galleryImg" />
                        <img src="/images/4.jpeg"  alt="Gallery" draggable="false" className="galleryImg" />
                        <img src="/images/5.jpeg"  alt="Gallery" draggable="false" className="galleryImg" />
                        <img src="/images/6.jpeg"  alt="Gallery" draggable="false" className="galleryImg" />
        </div>


      </section>

      {/* // <!-- ================================================   Goal & Motive   ================================================ --> */}
      <section id="goal_motive">
        <div className="container">

          <div className="d-flex my-4 my-lg-5">
            <div className="mx-auto">
              <h3 className="headding m-0">Goal & Motive</h3>
              <div className="underline"></div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src="./image/Goal_Motive.png" alt="Goal & Motive" draggable="false" className="img-fluid img_ani" />
            </div>
            <div className="col-lg-6">
              <ul className="goalText arrowTxt">
                <li className="d-flex f-20 align-items-start"><i
                  className="fad fa-angle-double-right missionIcn me-2"></i>
                  Making Education Easier.</li>
                <li className="d-flex f-20 align-items-start"><i
                  className="fad fa-angle-double-right missionIcn me-2"></i>
                  Provide Guidance For Career.</li>
                <li className="d-flex f-20 align-items-start"><i
                  className="fad fa-angle-double-right missionIcn me-2"></i>
                  Create Awareness For Future Opportunities.</li>
                <li className="d-flex f-20 align-items-start"><i
                  className="fad fa-angle-double-right missionIcn me-2"></i>
                  Create Interest In Sports.</li>
                <li className="d-flex f-20 align-items-start"><i
                  className="fad fa-angle-double-right missionIcn me-2"></i>
                  Make Ready For The Social Responsibilities.</li>
                <li className="d-flex f-20 align-items-start"><i
                  className="fad fa-angle-double-right missionIcn me-2"></i>
                  Make Better Life Of The People.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ================================================   Donate Now   ================================================ --> */}
      <section id="donate">
        <div className="container">

          <div className="d-flex my-4 my-lg-5">
            <div className="mx-auto">
              <h3 className="headding m-0">Donate Now</h3>
              <div className="underline"></div>
            </div>
          </div>

          <div className="row d-flex  flex-column-reverse flex-lg-row align-items-center">
            {/* <!-- ======================= Donor Details ====================== --> */}
            <div className="col-lg-6 formBg p-4">
              <form className="row g-3" onSubmit={e => submitForm(e)} ref={form}>
                <div className="col-md-12">
                  <label htmlFor="inputName4" className="form-label">Donor Name :</label>
                  <input type="text" className="form-control" id="inputName4" name="donorName" required />
                </div>
                <div className="col-12">
                  <label htmlFor="inputOrganization" className="form-label">Organization / Company Name :</label>
                  <input type="text" className="form-control" id="inputOrganization" name="Organization" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputemail4" className="form-label">Email :</label>
                  <input type="email" className="form-control" id="inputemail4" name="email" required />
                </div>
                <div className="col-md-6">
                  <label htmlFor="inputContact4" className="form-label">Contact No :</label>
                  <input type="number" className="form-control" id="inputContact4" name="contactNo" required />
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label">Address :</label>
                  <input type="" className="form-control" id="inputAddress" name="Address" required />
                </div>
                <div className="col-md-5">
                  <label htmlFor="inputCity" className="form-label">City :</label>
                  <input type="text" className="form-control" id="inputCity" name="city" required />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputState" className="form-label">State :</label>
                  <input type="text" className="form-control" id="inputState" name="state" required />
                </div>
                <div className="col-md-3">
                  <label htmlFor="inputPIN" className="form-label">PIN Code :</label>
                  <input type="number" className="form-control" id="inputPIN" name="pinCode" required />
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary w-100">Submit</button>
                </div>
              </form>
            </div>
            {/* <!-- ======================= Trust Bank Details ====================== --> */}
            <div className="col-lg-6 ps-lg-5">
              <div className="d-flex align-items-center">
                <div>
                  <i className="fas fa-address-card ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">Account Holder Name</h5>
                  <p className="card-text f-16 petaFont petaFontColor">SADBHAVNA CHARITABLE AND EDUCATION TRUST
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <i className="fas fa-university ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">Bank Name</h5>
                  <p className="card-text f-16 petaFont petaFontColor">IDBI Bank</p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <i className="fas fa-money-check-edit ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">IFSC Code</h5>
                  <p className="card-text f-16 petaFont petaFontColor">IBKL0001337</p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <i className="fas fa-map-marker-alt ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">Account Number</h5>
                  <p className="card-text f-16 petaFont petaFontColor">1337104000234696</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div>
                  {/* <!-- <i className="fas fa-map-marker-alt ConIcon"></i> --> */}
                  <i className="fas fa-money-check-alt ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">Account Type</h5>
                  <p className="card-text f-16 petaFont petaFontColor">Saving</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* <!-- ================================================   Contact Us   ================================================ --> */}
      <section className="mb-4 mb-lg-5" id="contact_us">
        <div className="container">

          <div className="d-flex my-4 my-lg-5">
            <div className="mx-auto">
              <h3 className="headding m-0">Contact Us</h3>
              <div className="underline"></div>
            </div>
          </div>

          <div className="row">
            {/* <!-- ======================= Contact Details ====================== --> */}
            <div className="col-lg-6">
              <div className="d-flex align-items-center">
                <div>
                  <i className="fas fa-phone-alt ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">Call</h5>
                  <p className="card-text f-16 petaFont petaFontColor d-flex flex-wrap"><span>+91 93285 20030 </span><span> / +91 83209 30923</span><span> / +91 94278 89573</span><span> / +91 99090 40002</span></p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <i className="fas fa-envelope ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">Email</h5>
                  <p className="card-text f-16 petaFont petaFontColor">scaet2012@gmail.com</p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <i className="fas fa-user-clock ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">Time</h5>
                  <p className="card-text f-16 petaFont petaFontColor">10:00 AM – 6:30 PM</p>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div>
                  <i className="fas fa-map-marker-alt ConIcon"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title f-20 headFont">Address</h5>
                  <p className="card-text f-16 petaFont petaFontColor">509, poddar arcade, opp. khand bazar,
                    varachha road, surat, Gujarat 395006</p>
                </div>
              </div>
            </div>

            {/* <!-- ======================= Map ====================== --> */}
            <div className="col-lg-6 mapBorder p-2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.6241659575353!2d72.84151261493564!3d21.20708398590204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fb358b670cf%3A0xec697e662c74e4e9!2ssadbhavna%20charitable%20and%20education%20trust!5e0!3m2!1sen!2sin!4v1641384746618!5m2!1sen!2sin"
                className="map" width="100%" height="100%" allowFullScreen="" loading="lazy"></iframe>
            </div>
          </div>

        </div>
      </section>

      {/* <!-- ================================================   Footer   ================================================ --> */}
      <footer className="py-4 py-lg-5">
        <div className="container">
          <div className="row">
            {/* <!-- ======================= Contact Headding ====================== --> */}
            <div className="col-lg-3">
              <a href="#header" className="footerText">
                <h1 className="pb-4 pb-lg-5">Sadbhavna Charitable And Education Trust</h1>
              </a>
              <div>
                {/* <!-- ======================= Contact Headding ====================== --> */}
                <span>
                  <h3 className="footerHead m-0">Contact Us</h3>
                  <div className="footerUnderline"></div>
                </span>
                {/* <!-- ======================= Social Media ====================== --> */}
                <div className="row pt-3">
                  <div className="col-6">
                    <ul>
                      <li className="d-flex align-items-center pt-4"><i
                        className="fab fa-facebook-square ctc me-2"></i>Facebook</li>
                      <li className="d-flex align-items-center pt-4"><i
                        className="fab fa-linkedin ctc me-2"></i>Linkedin</li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul>
                      <li className="d-flex align-items-center pt-4"><i
                        className="fab fa-instagram-square ctc me-2"></i>Instagram</li>
                      <li className="d-flex align-items-center pt-4"><i
                        className="fab fa-twitter-square ctc me-2"></i>Twitter</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>

            {/* <!-- ======================= Services ====================== --> */}
            <div className="col-lg-4">
              <span>
                <h3 className="footerHead m-0">Services</h3>
                <div className="footerUnderline"></div>
              </span>
              <div className="row mt-3">
                <div className="col-6">
                  <ul>
                    <li><a href="#about_us" className="petaFont cw socialMedia d-flex align-items-center"><i
                      className="fad fa-angle-double-right ctc me-2 my-3"></i> About Us</a></li>
                    <li><a href="#upcoming_projects"
                      className="petaFont cw socialMedia d-flex align-items-center"><i
                        className="fad fa-angle-double-right ctc me-2 my-3"></i> Upcoming Projects</a>
                    </li>
                    <li><a href="#contact_us" className="petaFont cw socialMedia d-flex align-items-center"><i
                      className="fad fa-angle-double-right ctc me-2 my-3"></i> Contact Us</a></li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul>
                    <li><a href="#mission_vision"
                      className="petaFont cw socialMedia d-flex align-items-center"><i
                        className="fad fa-angle-double-right ctc me-2 my-3"></i> Mission & Vision</a>
                    </li>
                    <li><a href="#goal_motive" className="petaFont cw socialMedia d-flex align-items-center"><i
                      className="fad fa-angle-double-right ctc me-2 my-3"></i>Goal & Motive</a></li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <!-- ======================= Contact Info ====================== --> */}
            <div className="col-lg-4">
              <span>
                <h3 className="footerHead m-0">Contact Info</h3>
                <div className="footerUnderline"></div>
              </span>
              <div className="row">
                <div className="col-lg-12">
                  <ul>
                    <li className="d-flex align-items-start pt-4">
                      <div><i className="fas fa-phone-alt ctc f-16 mt-1 me-2"></i></div>
                      <div className="d-flex flex-wrap"><span>+91 93285 20030 </span><span> / +91 83209 30923</span><span> / +91 94278 89573</span><span> / +91 99090 40002</span></div>
                    </li>
                    <li className="d-flex align-items-center pt-4"><i
                      className="fas fa-envelope ctc f-16 me-2"></i>scaet2012@gmail.com</li>
                    <li className="d-flex align-items-start pt-4"><i
                      className="fas fa-map-marker-alt f-16 mt-1 ctc me-2"></i>509, poddar arcade, opp. khand bazar, varachha road, surat, Gujarat 395006</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="white_line" />
          <h6 className="text-center f-16">Copyright © 2022 Sadbhavna Charitable And Education Trust. All Rights Reserved.
          </h6>
        </div>
      </footer>







    </>
  );
}

export default App;
